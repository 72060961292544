import { atom } from 'jotai';
import type { CloneCountryState } from '../utils/country-switch';

// Initial state
const initialCloneCountryState: CloneCountryState = {
    urlSegment: null,
    currentCountry: null,
    pageType: null,
    associatedPages: null,
};

// Atom
const cloneCountryStateAtom = atom<CloneCountryState>(initialCloneCountryState);

// Getter and Setter hooks
// eslint-disable-next-line import/no-unused-modules
export const getCloneCountryStateAtom = atom<CloneCountryState>((get) =>
    get(cloneCountryStateAtom)
);

export const setCloneCountryStateAtom = atom(
    null,
    (get, set, update: Partial<CloneCountryState>) => {
        const prev = get(cloneCountryStateAtom);
        set(cloneCountryStateAtom, {
            ...prev,
            ...update,
        });
    }
);
